import { LOCALE } from './locales';

export const ENV = 'coescotesting';
export const SELECTED_LOCALE = LOCALE.Colombia;
export const PAGE_TITLE = "Infoormación Factura Electrónica Cliente Eventual EDS Primax operadas por Coesco";
//export const API_ENDPOINT = 'http://45.79.27.28:8080/';
export const API_ENDPOINT = 'http://localhost:8000/';
export const RECAPTCHA_SITE_KEY = '6Le7zMcZAAAAAFavRdbC6xMOzAo2s1Obxe-4ezf8';
export const TERMS_TEXT = `La información personal suministrada a COESCO Colombia SAS, con NIT 901.300. 741-5 y domiciliada en Bogotá D.C., será tratada con el fin de cumplir los diferentes compromisos y obligaciones derivados de la relación comercial y para fines administrativos tales como administración de sistemas de información, contabilidad, facturación, etc. Con su aceptación manifiesta que:\n\n1. Autoriza de manera libre, previa, expresa e informada a COESCO Colombia S.A.S. y/o a la persona natural o jurídica a quién ésta eventualmente designe, para que trate los datos personales que le ha suministrado y/o que le suministrará.\n\n2. Conoce sus derechos, previstos en la constitución y en la ley 1581 de 2012, y entiende que para mayor información puede acceder a la política de tratamiento de la información, disponible en la oficina del Gerente de administración y control, ubicada en la Calle 90 No.19C-32 - Bogotá, Colombia, a quien puede contactar en el Teléfono: 571-6283323.`;
export const APP_COLORS =  {
    primary:"#0098cc",
    secondary:"#04B3FF",
    dark:"#000000",
    light:"#f2f2f2",
    shade:"#cccccc"

};
