import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {ENV} from "../../constants";
import { Button, Slide, DialogContent, DialogContentText, DialogActions, ButtonGroup } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props,ref){
    return <Slide direction="down" ref={ref} {...props} />;
})

export function AppDialog(props){
    let buttons;
    if(process.env.REACT_APP_ENV != "coesco"){
        buttons = <DialogActions>
            <Button variant="contained" size="medium" color="secondary"href="https://dominus.iapropiada.com/download/habeasdata" target="_BLANK">Descargar Politica de tratamiento de datos</Button>
            <Button variant="contained" size="medium" color="primary" onClick={(e) => props.onConfirm(e)}>Entendido</Button>
        </DialogActions>
    }else{
        buttons = <DialogActions>
            <Button variant="contained" size="medium" color="primary" onClick={(e) => props.onConfirm(e)}>Entendido</Button>
        </DialogActions>
    }

    return (
        <Dialog onClose={(e) => props.onClose(e)} open={props.open} maxWidth="md" TransitionComponent={Transition}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{whiteSpace:"pre-line"}}>
                    {props.text}
                </DialogContentText>
            </DialogContent>
            {buttons}
        </Dialog>
    );
}