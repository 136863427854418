import React, {useState, useEffect, useRef } from "react";
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'
import {Grid, Select,MenuItem, Checkbox, FormControl, FormControlLabel, TextField, Button, Hidden, IconButton, InputAdornment, CircularProgress} from "@material-ui/core";
import {useStyles} from "../../themeApp";
import { Formik } from "formik";
import {localizedValidationSchema, validationSchema} from '../../validations/ValidationForms'
import {getCd, getIdTypes, postPerson, saveRutfile, getDepartments, getCitiesDepartment, getCities, editPerson, verifyTokenCaptcha, setTypesPeople, queryPerson} from "../../request/requests"
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import CreateIcon from "@material-ui/icons/Create";
import DescriptionIcon from "@material-ui/icons/Description";
import {SendEmailUser} from "../Modals/SendEmailUser"
import {CheckUserAndKey} from "../Modals/CheckUserAndKey"
import {CheckOnlyKey} from "../Modals/CheckOnlyKey"
import {ButtonBilling} from "../Billing/ButtonBilling"
import { SearchSale } from "../Modals/SearchSale";
import {ActionBilling} from "../Billing/ActionBilling"
import { ErrorSharp } from "@material-ui/icons";
import { LOCALE } from '../../locales';




export function FormPerson(props){

    const {handlerSendingData, handlerShowErrorSnackbar, handlerErrorMessage, handlerShowInfoSnackbar, handlerInfoMessage, handlerShowDialog} = props;

    var recaptcha = useRef(null);
    const classes = useStyles();
    const [dataEdit, setdataEdit] = useState("");
    const [departments, setdepartments] = useState([]);
    const [departmentSelected, setdepartmentSelected] = useState(null);
    const [cities, setcities] = useState([]);
    const [allCities, setallCities] = useState([]);
    const [documentTypes, setdocumentTypes] = useState(null);
    //const [personTypes, setpersonTypes] = useState(null);
    const [dataPerson, setdataPerson] = useState("");
    const [isEdit, setisEdit] = useState(false);
    const [isRequired, setisRequired] = useState(false);
    const [namesRequired, setnamesRequired] = useState(false);
    const [editUser, seteditUser] = useState(false);
    const [hasKey, sethasKey] = useState(false);
    const [keyCheck, setkeyCheck] = useState("");
    const [editKey, seteditKey] = useState(false);
    const [isBilling, setisBilling] = useState(false);
    const [isSearchSale, setisSearchSale] = useState(false);
    const [verifiedToken, setverifiedToken] = useState("");
    const [verified, setverified] = useState(false);
    const [sale, setsale] = useState(null);
    const [loadingQuery, setLoadingQuery] = useState(false);
    
    /****INICIALIZAR VARIABLES****/
    useEffect(() => {
        handlerSendingData(true);
        loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
        //setpersonTypes(setTypesPeople())
        getIdTypes().then((data) => {
          if (data.error) {
            handlerErrorMessage(data.message);
            handlerShowErrorSnackbar(true);
          } else {
            //filtra la data para solo mostrar cedula o nit/rut
            setdocumentTypes(data.data.filter(document => localizedDocumentTypeFilter(document.id_dian)));
          }
        });
    
        getDepartments().then((data) => {
          if (data.error) {
            handlerErrorMessage(data.message);
            handlerShowErrorSnackbar(true);
          } else {
            setdepartments(data.data);
          }
        });
    
        getCities().then((data) => {
          if (data.error) {
            handlerErrorMessage(data.message);
            handlerShowErrorSnackbar(true);
          } else {
            setallCities(data.data);
          }
        });
        handlerSendingData(false);
        handleIsRequired(false);
        handleNamesRequired(true);
      }, []);

    /*---------------------------
    FUNCIONES FORMULARIO PERSONAS
    -----------------------------*/
    
    //valida el archivo del form
    function handleRut(e){
      if(e.target.files.length > 0){
        const extension_file = e.target.files[0].name.split(".").pop()
        if(extension_file != "jpg" && extension_file != "jpeg" && extension_file != "pdf"){
          handlerErrorMessage("El archivo debe estar en formato .jpg/.jpeg/.pdf");
          handlerShowErrorSnackbar(true);
          document.getElementById("user_rut").value = "";
        }
      }
    }

    /**
     * @author david.ciro 12-04-2021
     * @description Valida si el campo telefono tiene espacios y los surpime
     * tambien valida si el usuario es juridico y agrega digito de verificación
     */

    function validateSpaces(values, string,target_id){
      /*value = value.replace(/ /g, "")
      return value*/
      var out = '';
      var target = document.getElementById(target_id)
      var filtro = '1234567890';//Caracteres validos
    
      //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
      for (var i=0; i<string.length; i++){
        if (filtro.indexOf(string.charAt(i)) !== -1) {
          //Se añaden a la salida los caracteres validos
          out += string.charAt(i);
        }
      }

      localizedDocumentSpaceValidation(values, out, target);
    }

    /**
     * Obtiene la validación de espacios localizada
     * @param {Object} values 
     * @param {string} value 
     * @param {*} target 
     */
    function localizedDocumentSpaceValidation(values, value, target){
      switch (process.env.REACT_APP_LOCALE) {
        case LOCALE.Colombia:
          switch (values.peopleData.document_type_id) {
            case 5:
              target.value = value.substring(0,11);
              document.getElementById('btn-register-juridic').style.display = 'inline'
              setCd();             
              break;
            default:
              target.value = value;
              break;
          }
          break;
        case LOCALE.CostaRica:
          target.value = value;
          break;
        default:
          break;
      }
    }

    /**
     * Filtro de obtención de tipo de documento
     * @param {String} id_dian 
     * @returns 
     */
    function localizedDocumentTypeFilter(id_dian){
      switch (process.env.REACT_APP_LOCALE) {
        case LOCALE.Colombia:
          return (id_dian == '13' || id_dian == '22' || id_dian == '31');
        case LOCALE.CostaRica:
          return true;
        default:
          break;
      }
    }

    function verifyCallback(value){
      setverifiedToken(value);
    }

    //desactiva la ventana modal del envio de la key al email
    function disableModalEmailUser(){
      seteditUser(false);
    }

    //desactiva la ventana modal usuario clave
    function handleShowModalUserKey(){
      sethasKey(!hasKey);
    }

    //desactiva la ventana modal usuario clave
    function handleShowModalKey(){
      seteditKey(!editKey);
    }
  
    //recibe datos del usuario de la ventana modal
    function DataModal(data,key, dep, document_type){
      setdataPerson(data);
      setkeyCheck(key);
      setdepartmentSelected(dep);
    }

    function handleIsEdit(value){
      setisEdit(value)
    }

    function handleIsRequired(value){
      setisRequired(value)
    }

    function handleNamesRequired(value){
      setnamesRequired(value)
    }

    function handleDataEdit(value){
      setdataEdit(value);
    }

    function handleViewBilling(){
      setisBilling(!isBilling);
    }

    function handleSearchSale(){
      setisSearchSale(!isSearchSale);
    }

    function handleLoadSale(value){
      setsale(value);
    }

    async function updateToken(){
      await recaptcha.current.execute();
    }

    /* David A. Ciro Valida si el valor seleccionado es igual a 5 en  el select de tipo de documento*/

    function validateSelectTypeDoc(value){
      const target = document.getElementById("inpt-document");
      target.labels[0].innerText = "Documento*"

      handleIsRequired(false)
      handleNamesRequired(false)
      document.getElementById('parent-name').style.display = 'block'
      document.getElementById('parent-last-name').style.display = 'block'
      document.getElementById('parent-optional-name').style.display = 'block'
      document.getElementById('parent-optional-last-name').style.display = 'block'
      document.getElementById('parent-business-name').style.display = 'none'
      document.getElementById('parent-input-dv').style.display = 'none'

      document.getElementById('btn-register').style.display = 'inline'
      document.getElementById('btn-register-juridic').style.display = 'none'
      switch (process.env.REACT_APP_LOCALE) {
        case LOCALE.Colombia:
          switch (value) {
            case 5:
              target.labels[0].innerText = "Documento (SIN DV)*";
              var doc_number = document.getElementById('inpt-document').value
              //para volver la razon social requerida y los nombres y apellidos opcionales
              handleIsRequired(true);
              handleNamesRequired(false);
              //se ocultan los campos nombre y apellido y se muestra razon social
              document.getElementById('parent-name').style.display = 'none'
              document.getElementById('parent-last-name').style.display = 'none'
              document.getElementById('parent-optional-name').style.display = 'none'
              document.getElementById('parent-optional-last-name').style.display = 'none'
              document.getElementById('parent-business-name').style.display = 'block'

              document.getElementById('btn-register').style.display = 'none'
              document.getElementById('btn-register-juridic').style.display = 'inline'
              //mostrar DV
              if(doc_number != ''){
                if(doc_number.length > 9){
                  const element = document.getElementById('inpt-document')
                  element.value = element.value.substring(0,11)
                }
                setCd();
              }
              break;
            default:
              break;
          }
          break;
        case LOCALE.CostaRica:
          switch (value) {
            case 2:
            case 4:
              var doc_number = document.getElementById('inpt-document').value
              //para volver la razon social requerida y los nombres y apellidos opcionales
              handleIsRequired(true);
              handleNamesRequired(false);
              //se ocultan los campos nombre y apellido y se muestra razon social
              document.getElementById('parent-name').style.display = 'none'
              document.getElementById('parent-last-name').style.display = 'none'
              document.getElementById('parent-optional-name').style.display = 'none'
              document.getElementById('parent-optional-last-name').style.display = 'none'
              document.getElementById('parent-business-name').style.display = 'block'

              document.getElementById('btn-register').style.display = 'none'
              document.getElementById('btn-register-juridic').style.display = 'inline'
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }

    /**
     * 
     * @author david.ciro
     * @date 20-05-2021
     * @description setea el digito de verificacion en el campo dv
     */
    async function setCd(){
      const number_document = document.getElementById('inpt-document')
      var parent_dv = document.getElementById('parent-input-dv')
      if(number_document.value.length >= 9){
        if (number_document.value.length > 9) {
          handlerErrorMessage("El NIT que intenta registrar tiene mas de 9 digitos, porfavor verifiquelo antes de presionar Registrar.");
          handlerShowErrorSnackbar(true);
        }
        var dv = document.getElementById('inpt-dv')
        var result = await getCd(number_document.value)
        if(!result.error){
          dv.value = result.data
          parent_dv.style.display= 'block'
        }else{
          dv.value = 0
          parent_dv.style.display= 'none'
        }
      }else {
        parent_dv.style.display= 'none'
      }
    }
    //une varios campos en uno
    function mergeFields(fields){
      if(fields.length > 0){
        let result = '';
        for (let index = 0; index < fields.length; index++) {
          if(fields['index'] != ''){
            if(typeof fields[index] === 'string'){
              if(index == 0){
                result += fields[index]
              }else{
                result += ' ' + fields[index] 
              }
            }else{
              result += fields[index] 
            }
          }else{
            index++
          }
        }
        return result;
      }
    }

    //Envia los datos del formulario personas para almacenar
    async function sendPeopleData(values, method, resetForm) {
      //validacion temporal de archivos
      const fileRut = document.getElementById("user_rut")
      if (values.check_terms){
        /*COSNULTAR TOKEN RECAPTCHA*/
        var action = await verifyTokenCaptcha(verifiedToken);
        await updateToken();

        if (!action.error) {
          /*VALIDACION DE RECAPTCHA*/ 
          if(action.data.success){
        
              handlerSendingData(true);
              let contactData = values.contactData;
              let validContactData = true;//validateContacts(contactData);
        
              if (validContactData) {
                let wrappedContactData = Object.entries(values.contactData).map(
                  (entry) => {
                    var contact_type = 0;
                    switch (entry[0]) {
                      case "address":
                        contact_type = 1;
                        break;
                      case "postal_code":
                        contact_type = 2;
          
                        break;
                      case "residence_city":
                        contact_type = 3;
                        entry[1] = entry[1] ? entry[1].id : null;
                        break;
                      case "email_delivery":
                        contact_type = 4;
          
                        break;
                      case "email_personal":
                        contact_type = 5;
          
                        break;
          
                      case "phone":
                        contact_type = 6;
                        break;
                    }
                    return {
                      contact_type: contact_type,
                      contact_value: entry[1],
                    };
                  }
                );
                var tax_responsible = values.peopleData.tax_responsible ? "1" : "0";
                var has_retention = values.peopleData.has_retention ? "1" : "0";
  
                if(method == "POST"){
                  var person = { ...values.peopleData };
                  person.tax_responsible = tax_responsible;
                  person.has_retention = has_retention;
                  person.document_type_id = values.peopleData.document_type_id;
                  if(person['name'] != '' && person['optional_name'] != ''){
                    const name = person['name'];
                    const optional_name = person['optional_name'];
                    const names = Array(name,optional_name)
                    person['name'] = mergeFields(names)
                  }

                  if(person['last_name'] != '' && person['optional_last_name'] != ''){
                    const last_name = person['last_name'];
                    const last_optional_name = person['optional_last_name'];
                    const names = Array(last_name,last_optional_name)
                    person['last_name'] = mergeFields(names)
                  }

                  var data = {
                    person: person,
                    contactdata: wrappedContactData,
                  };

                  var action = await postPerson(data);
                }
  
                if(method == "PUT"){
                  var peopleData = {};
                  peopleData.name = values.peopleData.name ;
                  peopleData.last_name = values.peopleData.last_name ;
                  peopleData.tax_responsible = tax_responsible;
                  peopleData.has_retention = has_retention;
                  peopleData.business_name = values.peopleData.business_name;
                  if(values.peopleData['name'] != '' && values.peopleData['optional_name'] != ''){
                    const name = values.peopleData['name'] !== undefined ? values.peopleData['name'] : "";
                    const optional_name = values.peopleData['optional_name'] !== undefined ? values.peopleData['optional_name'] : "";
                    const names = Array(name,optional_name)
                    peopleData.name = mergeFields(names)
                  }

                  if(values.peopleData['last_name'] != '' && values.peopleData['optional_last_name'] != ''){
                    const last_name = values.peopleData['last_name'] !== undefined ? values.peopleData['last_name'] : "";
                    const last_optional_name = values.peopleData['optional_last_name'] !== undefined ? values.peopleData['optional_last_name'] : "";
                    const names = Array(last_name,last_optional_name)
                    peopleData.last_name = mergeFields(names)
                  }

                  var data = {
                    id: dataPerson.peopleData.id,
                    edition_key: keyCheck,
                    peopleData: peopleData,
                    contactData: wrappedContactData,
                  };
  
                  
                  var action = await editPerson(data);
                }
          
                if (!action.error) {
                  /**
                   * si el ENV es coesco o coescotesting se ejecuta este codigo de lo contrario no
                   */
                  /*if(person !== undefined && person.document_type_id == 5 && fileRut.files.length > 0 && (ENV === 'coescotesting' || ENV === 'coesco')){
                    var action_rut = await saveRutfile(action.data)
                    if(!action_rut.error){
                      setisEdit(false);
                      setdataPerson("");
                      handlerInfoMessage(action_rut.message);
                      handlerShowInfoSnackbar(true);
                    }else{
                      handlerErrorMessage(action_rut.message);
                      handlerShowErrorSnackbar(true);
                    }
                  }else{*/
                    setisEdit(false);
                    setdataPerson("");
                    handlerInfoMessage(action.message);
                    handlerShowInfoSnackbar(true);
                  //}
                } else {
                  handlerErrorMessage(action.message);
                  handlerShowErrorSnackbar(true);
                }
                handlerSendingData(false);
                
              } else {
                handlerSendingData(false);
              }
          } else {
            if (action.data["error-codes"].includes("timeout-or-duplicate")){
              handlerErrorMessage("Token de recaptcha ha expirado o es inválido, por favor intente de nuevo");
            }else{
              handlerErrorMessage(action.message);
              handlerShowErrorSnackbar(true);
            }
          }
        } 
      }else{
        handlerInfoMessage("Por favor acepte los Términos");
        handlerShowInfoSnackbar(true);
      }
    }
    
    //Carga los tipos de documetos
    function loadIdTypes() {
        if (documentTypes) {
          return documentTypes.map((item, index) => ({
            id: item.id,
            value: `${item.description} (${item.abbreviation})`,
            index: index,
          }));
        } else {
            return false;
        }
    }

    //Carga los tipos de persona
    /*function loadTypesPeople(){
      if(personTypes){
        return personTypes.map((item, index) => ({
          id: item.id,
          value: `${item.value}`,
          index: index,
        }))
      }
    }*/
    //Carga ciudades por el departamento seleccionado
    async function loadCitiesDepartment(data){
      handlerSendingData(true);
      setdepartmentSelected(data);
      if(data != null){
        await getCitiesDepartment(data).then((data) => {
          if (data.error) {
            handlerErrorMessage(data.message);
            handlerShowErrorSnackbar(true);
          } else {
            setcities(data.data);
          }
        });
      }else{
        setcities(null);
      }
      handlerSendingData(false);
    }

    //Revisa si existe al menos un contacto
    function validateContacts(data) {
        if (
        /*data.postal_code &&*/
        (data.residence_city && data.residence_city !== 0)
        ) {
        return true;
        } else {
        handlerErrorMessage(
            "Debe proveer la Ciudad de Residencia"
        );
        handlerShowErrorSnackbar(true);
        return false;
        }
    }

    async function queryDocument({values, setFieldValue}) {
      let response = await queryPerson(values.peopleData.document, values.peopleData.document_type_id);
      if (response.error) {
        handlerErrorMessage('La información de este documento no ha sido registrada');
        handlerShowErrorSnackbar(true);
        return;
      }

      setFieldValue('peopleData.document_type_id', response.data.documentTypeId);
      validateSelectTypeDoc(response.data.documentTypeId);
      let name = response.data.name.split(' ')[0];
      let optionalName = response.data.name.split(' ')[1] ?? '';
      let lastName = response.data.lastName.split(' ')[0];
      let optionalLastName = response.data.lastName.split(' ').length > 1 ? response.data.lastName.split(' ').slice(1).join(" ") : '';
      switch(process.env.REACT_APP_LOCALE) {
        case LOCALE.CostaRica:
          if (response.data.documentTypeId == 2 || response.data.documentTypeId == 4) {
            setFieldValue('peopleData.business_name', response.data.name);
          }else {
            setFieldValue('peopleData.name', name);
            setFieldValue('peopleData.optional_name', optionalName);
            setFieldValue('peopleData.last_name', lastName);
            setFieldValue('peopleData.optional_last_name', optionalLastName);
          }

          setFieldValue('contactData.email_delivery', response.data.email ?? '');
          if (response.data.email) {
            handlerInfoMessage('La persona ya se encuentra registrada');
            handlerShowInfoSnackbar(true);
          }
          break;
        case LOCALE.Colombia:
          handlerInfoMessage('La persona ya se encuentra registrada');
          handlerShowInfoSnackbar(true);
          if (response.data.documentTypeId == 5) {
            setFieldValue('peopleData.business_name', response.data.name);
          }else {
            setFieldValue('peopleData.name', name);
            setFieldValue('peopleData.optional_name', optionalName);
            setFieldValue('peopleData.last_name', lastName);
            setFieldValue('peopleData.optional_last_name', optionalLastName);
          }

          setFieldValue('contactData.email_delivery', response.data.email ?? '');
          break;
        default:
          return;
      }

    }

    async function handleQueryDocument(passArgs) {
      setLoadingQuery(true);
      try{
        await queryDocument(passArgs);
      } finally {
        setLoadingQuery(false);
      }
    }

    /*---------------------------
    VISTA DEL FORMULARIO PERSONAS
    -----------------------------*/
    return(
        <div className={classes.app}>
            <Grid container justify="center" style={{marginBottom: "20px"}}>
              <h3 className={classes.formText}>Información Personal</h3>
            </Grid>
            <Grid item style={{ backgroundColor: process.env.REACT_APP_COLOR_PRIMARY, padding: 0,  marginTop: 10, paddingBottom: 5, }} xs={12} md={12} >
                <Grid container justify="center">
                  <h4 className={classes.formText} style={{padding: "10px"}}>
                    Debes llenar todos los campos marcados con *
                  </h4>
                </Grid>
            </Grid>

            <Grid container justify="center" style={{marginBottom: "20px"}}>
                <Formik
                    enctype="multipart/form-data"
                    enableReinitialize ={true}
                    initialValues={{
                    peopleData: dataPerson ? dataPerson.peopleData : {
                        document_type_id: loadIdTypes() ? loadIdTypes()[0].id : 1,
                        document: "",
                        name: "",
                        optional_name: "",
                        last_name: "",
                        optional_last_name: "",
                        tax_responsible: false,
                        has_retention: false,
                        business_name: ""
                        //kind_person: loadTypesPeople() ? loadTypesPeople()[0].id : 0,
                    },
                    contactData: dataPerson ? dataPerson.contactData : {
                        address: "",
                        postal_code: "",
                        residence_city: null,
                        email_delivery: "",
                        email_personal: "",
                        phone: "",
                    },
                    check_terms: (process.env.REACT_APP_LOCALE == LOCALE.CostaRica),
                    }}
                    validationSchema={localizedValidationSchema()}
                    validateOnChange={false}
                    onSubmit={(values, { resetForm }) => sendPeopleData(values, isEdit ? "PUT" : "POST" ,resetForm)
                    }
                >

                  {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched,}) => (
                    <Grid item id="form-container" md={8}>
                      
                      {/*------------------------
                      CAMPOS DATOS DE LA PERSONA
                      --------------------------*/}
                      <Grid  container direction="column" justify="center" spacing={2} className={classes.root} >
                        <Grid container direction="row" className={classes.formRow} justify="space-between" alignItems="flex-start" style={{padding: "5px 20px 5px 20px"}} >
                          <Grid className={classes.select} item xs={12} md={3} style={{paddingTop:'15px'}}>
                            <Select id={'inpt-type-doc'} value={values.peopleData.document_type_id} onChange={(e)=>
                              {
                                //validamos el tipo de documento e invocamos el handlechange()
                                validateSelectTypeDoc(e.target.value)
                                handleChange("peopleData.document_type_id")
                                setFieldValue("peopleData.document_type_id", e.target.value)
                                setFieldValue("peopleData.document", document.getElementById('inpt-document').value)
                              }} 
                              disabled={isEdit} autoWidth
                            >
                              {documentTypes ? documentTypes.map((item, index) => (

                                    <MenuItem key={item.id} value={item.id} >{`${item.description} (${item.abbreviation})`}</MenuItem>

                                  ))
                                : null}
                            </Select>
                          </Grid>
                          <Grid className={classes.item} xs={12} item md={3}>
                            <FormControl>
                              <TextField
                                name="peopleData.document"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.peopleData &&
                                  errors.peopleData.document &&
                                  touched.peopleData &&
                                  touched.peopleData.document
                                }
                                helperText={
                                  errors.peopleData &&
                                  errors.peopleData.document &&
                                  touched.peopleData &&
                                  touched.peopleData.document
                                    ? errors.peopleData.document
                                    : ""
                                }
                                label="Documento"
                                id="inpt-document"
                                InputLabelProps={{
                                  required: true,
                                  color: "primary",
                                  error: false,
                                  type:"text",
                                }}
                                inputProps = {{
                                  maxLength:(process.env.REACT_APP_LOCALE == LOCALE.CostaRica) ? 12 : 11
                                }}
                                InputProps={{
                                  endAdornment: loadingQuery ? (<CircularProgress size={20} component="span" color="primary" />) : (<InputAdornment position="end">
                                    <IconButton color="primary" aria-label="Buscar documento" disabled={!(values.peopleData.document.length >= 8)} component="span" onClick={() => handleQueryDocument({values, setFieldValue})}>
                                      <SearchIcon/>
                                    </IconButton>
                                    </InputAdornment> )
                                }}
                                disabled={isEdit}
                                value={values.peopleData.document}
                                onKeyUp={(e)=>{
                                  validateSpaces(values, e.target.value,e.target.id)
                                  setFieldValue("peopleData.document", e.target.value)
                                }}
                              />

                            </FormControl>
                          </Grid>

                          <Grid id={'parent-input-dv'} className={classes.item} xs={12} item md={3} style={{display:'none'}}>
                            <FormControl>
                              <TextField
                                name="dv"
                                label="DV"
                                id="inpt-dv"
                                disabled={true}
                              />
                            </FormControl>
                          </Grid>

                          <Grid id="parent-business-name" className={classes.item} item xs={12} md={3} style={{display:'none', position:'relative', float:'left'}}>
                            <FormControl>
                              <TextField
                                name="peopleData.business_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.peopleData &&
                                  errors.peopleData.business_name &&
                                  touched.peopleData &&
                                  touched.peopleData.business_name
                                }
                                helperText={
                                  errors.peopleData &&
                                  errors.peopleData.business_name &&
                                  touched.peopleData &&
                                  touched.peopleData.business_name
                                    ? errors.peopleData.business_name
                                    : ""
                                }
                                label={(process.env.REACT_APP_LOCALE == LOCALE.CostaRica) ? 'Compañía' : 'Razón Social'}
                                id="inpt-last-business-name"
                                value={values.peopleData.business_name}
                                InputLabelProps={{
                                  required: isRequired,
                                  color: "primary",
                                  error: false,
                                  type:'text'
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid id="parent-name" className={classes.item} item xs={12} md={4} >
                            <FormControl>
                              <TextField
                                name="peopleData.name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.peopleData &&
                                  errors.peopleData.name &&
                                  touched.peopleData &&
                                  touched.peopleData.name
                                }
                                helperText={
                                  errors.peopleData &&
                                  errors.peopleData.name &&
                                  touched.peopleData &&
                                  touched.peopleData.name
                                    ? errors.peopleData.name
                                    : ""
                                }
                                label="Primer nombre"
                                id="inpt-name"
                                value={values.peopleData.name}
                                InputLabelProps={{
                                  required: namesRequired,
                                  color: "primary",
                                  error: false,
                                  type:"text"
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        
                        
                        <Grid container direction="row" className={classes.formRow} justify="space-between" style={{padding: "5px 20px 5px 20px"}} >
                          <Grid id="parent-optional-name" className={classes.item} item xs={12} md={4}>
                            <FormControl>
                                <TextField
                                  name="peopleData.optional_name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.peopleData &&
                                    errors.peopleData.optional_name &&
                                    touched.peopleData && 
                                    touched.peopleData.optional_name
                                  }
                                  helperText={
                                    errors.peopleData &&
                                    errors.peopleData.optional_name &&
                                    touched.peopleData &&
                                    touched.peopleData.optional_name
                                      ? errors.peopleData.optional_name
                                      : ""
                                  }

                                  label="Segundo nombre"
                                  value={values.peopleData.optional_name}
                                  InputLabelProps={{
                                    //required: namesRequired,
                                    color: "primary",
                                    error: false,
                                    type:"text"
                                  }}
                                />
                            </FormControl>
                          </Grid>
                          <Grid id="parent-last-name" className={classes.item} item xs={12} md={4}>
                            <FormControl>
                              <TextField
                                name="peopleData.last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.peopleData &&
                                  errors.peopleData.last_name &&
                                  touched.peopleData &&
                                  touched.peopleData.last_name
                                }
                                helperText={
                                  errors.peopleData &&
                                  errors.peopleData.last_name &&
                                  touched.peopleData &&
                                  touched.peopleData.last_name
                                    ? errors.peopleData.last_name
                                    : ""
                                }
                                label="Primer apellido"
                                id="inpt-last-name"
                                value={values.peopleData.last_name}
                                InputLabelProps={{
                                  required: namesRequired,
                                  color: "primary",
                                  error: false,
                                  type:'text'
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid id="parent-optional-last-name" className={classes.item} item xs={12} md={4}>
                            <FormControl>
                              <TextField
                                name="peopleData.optional_last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.peopleData &&
                                  errors.peopleData.optional_last_name &&
                                  touched.peopleData &&
                                  touched.peopleData.optional_last_name
                                }
                                helperText={
                                  errors.peopleData &&
                                  errors.peopleData.optional_last_name &&
                                  touched.peopleData &&
                                  touched.peopleData.optional_last_name
                                    ? errors.peopleData.optional_last_name
                                    : ""
                                }
                                label="Segundo apellido"
                                id="inpt-optional-last-name"
                                value={values.peopleData.optional_last_name}
                                InputLabelProps={{
                                  //required: namesRequired,
                                  color: "primary",
                                  error: false,
                                  type:'text'
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/*------------------------
                            ADJUNTOS PARA TIP DOC RUT
                      --------------------------*/}
                        <Grid 
                          container 
                          direction="row" 
                          id="row_rut_files" 
                          className={classes.formRow} 
                          justify="space-between" 
                          alignItems="flex-start" 
                          style={{padding: "5px 20px 5px 20px",display:"none"}} 
                        >
                            <Grid item xs={12} md={12}>
                              <label>Adjuntar RUT</label>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <input 
                                type={"file"}
                                variant="outlined"
                                accept={".jpg,.jpeg,.pdf"}
                                label="Agregar RUT"
                                name="peopleData.rut_file"
                                id="user_rut"
                                onChange={e => handleRut(e)}
                                error= {
                                  errors.peopleData &&
                                  errors.peopleData.rut_file &&
                                  touched.peopleData &&
                                  touched.peopleData.rut_file
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <small style={{color: process.env.REACT_APP_COLOR_PRIMARY}}>Formatos permitidos .pdf/.jpg/.jpeg</small>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" className={classes.formRow} justify="space-between" alignItems="flex-start" style={{padding: "5px 20px 5px 20px"}}>
                          
                          {/*onChange={(event, option) =>
                                setFieldValue("contactData.residence_city", option)
                              }*/}
                              {/*handleChange("peopleData.document_type_id")*/}
                          {/*<Grid className={classes.select} item xs={12} md={4}>
                            <Select value={values.peopleData.kind_person}
                                name="peopleData.kind_person"
                                onBlur={handleBlur}
                                disabled={false} autoWidth
                                onChange={(e)=>
                                  {
                                    handleChange("peopleData.kind_person")
                                    setFieldValue("peopleData.kind_person", e.target.value)
                                  }}
                            >
                              
                              {personTypes ? personTypes.map((item, index) => (

                                <MenuItem key={item.id} value={item.id} >{`${item.value}`}</MenuItem>

                              ))
                              : null}
                            </Select>
                              </Grid>*/}
                          {/*<Grid item xs={12} md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox value={values.peopleData.has_retention} color="primary" onChange={handleChange("peopleData.has_retention")} checked={values.peopleData.has_retention} name="has-retention" />
                              }
                              label="Retención"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox value={values.peopleData.tax_responsible} color="primary" onChange={handleChange("peopleData.tax_responsible")} checked={values.peopleData.tax_responsible} name="tax-responsable"/>
                              }
                              label="Responsable IVA"
                            />
                            </Grid>*/}
                          <Grid className={classes.item} item xs={12} md={4}>
                            <FormControl >
                              <TextField
                                name="contactData.email_delivery"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.contactData &&
                                  errors.contactData.email_delivery &&
                                  touched.contactData &&
                                  touched.contactData.email_delivery
                                }
                                helperText={
                                  errors.contactData &&
                                  errors.contactData.email_delivery &&
                                  touched.contactData &&
                                  touched.contactData.email_delivery
                                    ? errors.contactData.email_delivery
                                    : ""
                                }
                                label="Email"
                                id="inpt-email"
                                fullWidth={true}
                                InputLabelProps={{
                                  required: true,
                                  type:'email'
                                }}
                                value={values.contactData.email_delivery}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/*------------------------
                        CAMPOS DATOS DE CONTACTO
                        --------------------------*/}
                        {/*<Grid item style={{ backgroundColor: process.env.REACT_APP_COLOR_PRIMARY, padding: 0,  marginTop: 10, paddingBottom: 5, }} xs={12} md={12} >
                          <Grid container justify="center">
                            <h4 className={classes.formText} style={{padding: "10px"}}>
                              Información de Contacto (Debes llenar todos los campos marcados con *)
                            </h4>
                          </Grid>
                      </Grid>*/}


                        {/*<Grid container direction="row" className={classes.formRow} justify="space-between" alignItems="flex-start" style={{padding: "5px 20px 5px 20px"}}>
                          

                          <Grid className={classes.item} item xs={12} md={3}>
                            <Autocomplete
                              options={(departments) ? departments : []}
                              style={{ width: "80%" }}
                              value={departmentSelected}
                              getOptionLabel={(option) => option.name}
                              noOptionsText="Sin resultados..."
                              renderInput={(params) => (
                                <TextField {...params} label="Dpto de Residencia" />
                              )}
                              onChange={(event, option) =>
                                loadCitiesDepartment(option)
                              }  
                            />
                          </Grid>


                          <Grid className={classes.item} item xs={12} md={3}>
                            <Autocomplete
                              options={(cities) ? cities : []}
                              style={{ width: "80%" }}
                              value={values.contactData.residence_city}
                              getOptionLabel={(option) => option.name}
                              noOptionsText="Sin resultados..."
                              renderInput={(params) => (
                                <TextField {...params} label="Municipio de Residencia" />
                              )}
                              onChange={(event, option) =>
                                setFieldValue("contactData.residence_city", option)
                              }
                            />
                          </Grid>


                          <Grid className={classes.item} item xs={12} md={6}>
                            <FormControl style={{width: "80%"}}>
                              <TextField
                                name="contactData.address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.contactData &&
                                  errors.contactData.address &&
                                  touched.contactData &&
                                  touched.contactData.address
                                }
                                helperText={
                                  errors.contactData &&
                                  errors.contactData.address &&
                                  touched.contactData &&
                                  touched.contactData.address
                                    ? errors.contactData.address
                                    : ""
                                }
                                label="Dirección"
                                id="inpt-address"
                                value={values.contactData.address}

                                InputLabelProps={{
                                  required: false,
                                  type:'text'
                                }}
                              />
                            </FormControl>
                          </Grid>
                          

                              </Grid>*/}


                        <Grid container direction="row" className={classes.formRow} alignContent="center" alignItems="flex-start" style={{padding: "5px 20px 5px 20px"}} >


                          {/*<Grid className={classes.item} item xs={12} md={3}>
                            <FormControl style={{width: "80%"}}>
                              <TextField
                                name="contactData.postal_code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.contactData &&
                                  errors.contactData.postal_code &&
                                  touched.contactData &&
                                  touched.contactData.postal_code
                                }
                                helperText={
                                  errors.contactData &&
                                  errors.contactData.postal_code &&
                                  touched.contactData &&
                                  touched.contactData.postal_code
                                    ? errors.contactData.postal_code
                                    : ""
                                }
                                value={values.contactData.postal_code}
                                label="Código Postal"
                                id="inpt-postalcode"
                                inputProps = {{maxLength:6}}
                                InputLabelProps={{
                                  required: false,
                                  type:'number'
                                }}
                                onKeyUp={(e)=>{
                                  validateSpaces(e.target.value,e.target.id)
                                  setFieldValue("contactData.postal_code", e.target.value)
                                }}
                              />
                            </FormControl>
                          </Grid>


                          <Grid className={classes.item} item xs={12} md={3}>
                            <FormControl style={{width: "80%"}}>
                              <TextField
                                name="contactData.phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={(e)=>{
                                  validateSpaces(e.target.value,e.target.id)
                                  setFieldValue("contactData.phone", e.target.value)
                                }}
                                error={
                                  errors.contactData &&
                                  errors.contactData.phone &&
                                  touched.contactData &&
                                  touched.contactData.phone
                                }
                                helperText={
                                  errors.contactData &&
                                  errors.contactData.phone &&
                                  touched.contactData &&
                                  touched.contactData.phone
                                    ? errors.contactData.phone
                                    : ""
                                }
                                value={values.contactData.phone}

                                label="Telefono"
                                id="inpt-phone"
                                inputProps = {{maxLength:10}}
                                fullWidth={true}
                                InputLabelProps={{
                                  required: false,
                                  type:'tel'
                                }}
                              />
                            </FormControl>
                              </Grid>


                          <Grid className={classes.item} item xs={12} md={6}>
                            <FormControl style={{width: "80%"}}>
                              <TextField
                                name="contactData.email_delivery"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.contactData &&
                                  errors.contactData.email_delivery &&
                                  touched.contactData &&
                                  touched.contactData.email_delivery
                                }
                                helperText={
                                  errors.contactData &&
                                  errors.contactData.email_delivery &&
                                  touched.contactData &&
                                  touched.contactData.email_delivery
                                    ? errors.contactData.email_delivery
                                    : ""
                                }
                                label="Email"
                                id="inpt-email"
                                fullWidth={true}
                                InputLabelProps={{
                                  required: true,
                                  type:'email'
                                }}
                                value={values.contactData.email_delivery}
                              />
                            </FormControl>
                          </Grid>*/}

                          {/*------------------------
                          CAMPO DE ACEPTAR LOS TERMINOS 
                          --------------------------*/}
                          {(process.env.REACT_APP_LOCALE == LOCALE.Colombia) ? (
                          <Grid container direction="column" style={{ marginTop: 20 }} className={classes.formRow} justify="center" alignItems="center" >


                            <Grid className={classes.item} xs={12} item md={12}>
                              <FormControl>
                                <Button
                                  type="submit"
                                  id="btn-search"
                                  variant="contained"
                                  size="medium"
                                  color="primary"
                                  onClick={() => { handlerShowDialog(true)}}
                                  startIcon={<DescriptionIcon />}
                                  style = {{backgroundColor: "transparent", boxShadow: "none", color:process.env.REACT_APP_COLOR_PRIMARY}}
                                  className={classes.headerText}
                                >
                                  Para conocer los términos haga click AQUí
                                </Button>
                              </FormControl>
                            </Grid>
                          </Grid>) :null}


                          {(process.env.REACT_APP_LOCALE == LOCALE.Colombia) ? (
                          <Grid container direction="column" style={{ marginTop: 20 }} className={classes.formRow} justify="center" alignItems="center" >

                            <Grid item xs={12} md={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={values.check_terms}
                                    color="primary"
                                    onChange={handleChange("check_terms")}
                                    checked={values.check_terms}
                                    name="accepted-terms"
                                  />
                                }
                                label="Sí, Acepto los Términos"
                              />
                            </Grid>

                          </Grid>): null}

                          <Grid container direction="column" style={{ marginTop: 20 }} className={classes.formRow} justify="center" alignItems="center" >

                          <ReCaptcha
                              ref={recaptcha}
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                              action='action_name'
                              verifyCallback={verifyCallback}
                          />
                            
                          </Grid>
                          
                          {/*-----------------------------
                          BOTONES DE ACCION DEL FORMULARIO 
                          ------------------------------*/}
                          <Grid container direction="row" style={{ marginTop: 35 }} justify="center" alignItems="center">

                            <Grid item>
                              {/**btn de registro persona natural */} 
                              <Button
                                style={{
                                marginRight: "30px"}}
                        
                                id="btn-register"
                                variant="contained"
                                size="medium"
                                color="primary"
                                disabled={
                                  !values.peopleData.name ||
                                  !values.peopleData.last_name ||
                                  !values.peopleData.document
                                }
                                onClick={handleSubmit}
                                startIcon={<SaveIcon />}
                              >
                                {isEdit ? "Guardar cambios" : "Registrar"}
                              </Button>
                              {/** btn de persona juridica o con nit */}
                              <Button
                                style={{
                                  marginRight: "30px",
                                  display: "none"
                                }}
                        
                                id="btn-register-juridic"
                                variant="contained"
                                size="medium"
                                color="primary"
                                /*disabled={
                                  !values.peopleData.business_name ||
                                  !values.peopleData.document
                                }*/
                                onClick={handleSubmit}
                                startIcon={<SaveIcon />}
                              >
                                {isEdit ? "Guardar cambios" : "Registrar"}
                              </Button>
                            </Grid>

                            <Grid item>

                              <Button
                                id="btn-edit"
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={() => seteditUser(true)}
                                startIcon={<CreateIcon />}
                                disabled = {isEdit}
                              >
                                Editar
                              </Button>

                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
            </Grid>

            {/*MODAL ENVIAR E-MAIL EDICION*/}
            <SendEmailUser 
              handlerSendingData={handlerSendingData}
              handlerShowErrorSnackbar={handlerShowErrorSnackbar}
              handlerErrorMessage={handlerErrorMessage}
              handlerShowInfoSnackbar={handlerShowInfoSnackbar}
              handlerInfoMessage={handlerInfoMessage}
              editUser={editUser}
              handleShowModalUserKey={handleShowModalUserKey}
              disableModalEmailUser={disableModalEmailUser}
              handleDataEdit={handleDataEdit}
              handleShowModalKey={handleShowModalKey}
              idTypes={documentTypes}>
            </SendEmailUser>

            {/*MODAL VERIFICAR USER Y KEY*/}
            <CheckOnlyKey
              handlerSendingData={handlerSendingData}
              handlerShowErrorSnackbar={handlerShowErrorSnackbar}
              handlerErrorMessage={handlerErrorMessage}
              handlerShowInfoSnackbar={handlerShowInfoSnackbar}
              handlerInfoMessage={handlerInfoMessage}
              DataModal={DataModal}
              handleIsEdit={handleIsEdit}
              validateSelectTypeDoc={validateSelectTypeDoc}
              handleShowModalKey={handleShowModalKey}
              dataEdit={dataEdit}
              departments={departments}
              allCities={allCities}
              editKey={editKey}
            ></CheckOnlyKey>

            {/*MODAL VERIFICAR USER Y KEY*/}
            <CheckUserAndKey 
              handlerSendingData={handlerSendingData}
              handlerShowErrorSnackbar={handlerShowErrorSnackbar}
              handlerErrorMessage={handlerErrorMessage}
              handlerShowInfoSnackbar={handlerShowInfoSnackbar}
              handlerInfoMessage={handlerInfoMessage}
              handleShowModalUserKey={handleShowModalUserKey}
              DataModal={DataModal}
              handleIsEdit={handleIsEdit}
              idTypes={documentTypes}
              hasKey={hasKey}
              departments={departments}
              allCities={allCities}>      
            </CheckUserAndKey>

            {/*isBilling ? 
            
            <ActionBilling
              handlerSendingData={handlerSendingData}
              handlerShowErrorSnackbar={handlerShowErrorSnackbar}
              handlerErrorMessage={handlerErrorMessage}
              handlerShowInfoSnackbar={handlerShowInfoSnackbar}
              handlerInfoMessage={handlerInfoMessage}
              sale={sale}
              idTypes={documentTypes}
              handleViewBilling={handleViewBilling}
            ></ActionBilling>

            :

            <ButtonBilling        
              handleSearchSale={handleSearchSale}
              >  
            </ButtonBilling>*/

            }

            <SearchSale
              handlerSendingData={handlerSendingData}
              handlerShowErrorSnackbar={handlerShowErrorSnackbar}
              handlerErrorMessage={handlerErrorMessage}
              handlerShowInfoSnackbar={handlerShowInfoSnackbar}
              handlerInfoMessage={handlerInfoMessage}
              handleViewBilling={handleViewBilling}
              handleSearchSale={handleSearchSale}
              handleLoadSale={handleLoadSale}
              isBilling={isSearchSale}
            >
            </SearchSale>
        </div>
    );
}